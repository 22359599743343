<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-07 17:26:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-20 21:39:17
-->
<template>
  <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px" :width="1000" v-model="visible" :footer="null" :maskClosable="false">
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="所属渠道" prop="followType">
            <a-radio-group v-model="rowData.followType" @change="onFollowTypeChange">
              <a-radio :value="1"> 客户 </a-radio>
              <a-radio :value="2"> 渠道 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :autoLink="false"
            ref="test"
            :label="followTypeName"
            :prop="item && item.cusName != undefined ? 'cusName' : 'cusId'"
          >
            <a-input
              v-model="rowData.cusName"
              v-if="item && item.cusName != undefined"
              placeholder="请输入"
              disabled
            ></a-input>
            <a-select placeholder="请选择" v-model="cus" @change="onCusChange" label-in-value v-else>
              <a-select-option v-for="(item, index) in dataList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="手机号" prop="phone">
            <a-input
              :disabled="item != undefined ? true : false || handle === 'check' ? true : false"
              v-model="rowData.phone"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="所属门店" prop="storeId" :autoLink="false" ref="item">
            <a-select
              placeholder="请选择"
              v-model="rowData.storeId"
              :disabled="handle === 'check' ? true : false"
              @change="onStoreChange"
            >
              <a-select-option :value="item.id" v-for="(item, index) in followerList" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item :autoLink="false" ref="text" label="跟进人" prop="followUpId">
            <a-select
              placeholder="请选择"
              label-in-value
              v-model="followUp"
              :disabled="handle === 'check' ? true : false"
              @change="onFollowChange"
            >
              <a-select-option :value="item.id" v-for="(item, index) in managerList" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="跟进时间" prop="FollowUpTime" :autoLink="false" ref="tesxt">
            <DatePicker
              :startTime.sync="rowData.FollowUpTime"
              :disabled="handle === 'check' ? true : false"
            ></DatePicker>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="跟进方式" prop="followUpModes">
            <a-select
              placeholder="请选择"
              v-model="rowData.followUpModes"
              :disabled="handle === 'check' ? true : false"
            >
              <a-select-option :value="item.id" v-for="(item, index) in followUpType" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="费用支出">
            <a-input-number
              :min="1"
              :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              v-model="rowData.expensesPay"
              :disabled="handle === 'check' ? true : false"
              style="width: 100%"
            ></a-input-number>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="相关资料" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <QiniuUpload
              type="image"
              :value.sync="rowData.relevantData"
              :disabled="handle === 'check' ? true : false"
            ></QiniuUpload>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="跟进说明" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-textarea
              v-model="rowData.remark"
              :disabled="handle === 'check' ? true : false"
              placeholder="请输入"
              :auto-size="{ minRows: 3, maxRows: 21 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="btns-footer">
      <a-button type="default" @click="onCancel">取消</a-button>
      <a-button type="primary" @click="toSubmit()" v-if="handle !== 'check'">提交</a-button>
    </div>
  </a-modal>
</template>

<script>
import {
  addDealerHistoryFollowUp,
  editDealerHistoryFollowUp,
  selectByIdDealerHistoryFollowUp,
} from '../api/DealerCustomerInfoApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        followUpId: [{ required: true, message: '请选择跟进人', trigger: 'blur' }],
        executorId: [{ required: true, message: '请选择执行人', trigger: 'change' }],
        targetAmount: [{ required: true, message: '请输入目标金额', trigger: 'blur' }],
        executionTime: [{ required: true, message: '请选择执行时间', trigger: 'change' }],
        createUser: [{ required: true, message: '请输入创建人', trigger: 'blur' }],
        cusName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        cusId: [{ required: true, message: '请选择客户名称', trigger: 'blur' }],
        followType: [{ required: true, message: '请选择所属渠道', trigger: 'change' }],
        storeId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
      },
      managerList: [],
      followUpType: [
        {
          id: 1,
          name: '登门拜访',
        },
        {
          id: 2,
          name: '微信',
        },
        {
          id: 3,
          name: '电话',
        },
      ],
      followerList: [],
      dataList: [],
      followUp: {},
      cus: {},
      followTypeName: '客户名称'
    }
  },

  props: {
    item: {
      type: [Object, Array],
      default: () => {},
    },
  },

  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.followUp = {}
      this.cus = {}
      this.rowData.executionTime = null
      // 获取门店
      // this.axios.get(`/api/dealer/dealer/dealerInfo/searchByIdentity`).then((res) => {
      //   this.followerList = res.body
      //   console.log("4444",this.followerList)
      // })
       // 获取门店
      this.axios.get(`/api/base/system/dealer/manager/getMinePower?flagPersonData=false`).then((res) => {
        this.followerList = res.body.powerVOList
      })
      
      this.getCustomerList()

      if (handle === 'add') {
        this.$set(this.rowData, 'topTask', true)
        this.$set(this.rowData, 'taskMode', 0)
        this.$set(this.rowData, 'followType', 1)
      }
      if (handle == 'edit' || handle === 'check') {
        selectByIdDealerHistoryFollowUp(row.id).then((res) => {
          this.getManager(res.body.storeId)
          this.rowData = res.body
          this.followUp = [
            {
              label: res.body.followUpPerson,
              key: res.body.followUpId,
            },
          ]
          this.cus = [
            {
              label: res.body.cusName,
              key: res.body.cusId,
            },
          ]
          // this.rowData.cusName = this.item.name
          // this.rowData.cusId = this.item.cusId
          // this.rowData.phone = this.item.phone
          // this.rowData.followUpId = this.$store.getters.userInfo.id
        })
      }
      if (this.item != undefined) {
        this.rowData.cusName = this.item.name
        this.rowData.cusId = this.item.cusId
        this.rowData.phone = this.item.phone
        this.rowData.followUpId = this.$store.getters.userInfo.id
      }
    },

    getCustomerList() {
      //获取客户
      this.axios.get(`/api/dealer/customer/dealerCustomerInfo/list?pageSize=99999`).then((res) => {
        this.dataList = res.body.records
      })
    },

    getChannelList() {
      //获取渠道
      this.axios.get(`/api/dealer/source/customerSource/list?pageSize=99999`).then((res) => {
        this.dataList = res.body.records.map(e => {
          return {
            ...e,
            name: e.customerName
          }
        })
      })
    },

    onFollowTypeChange(val) {
      let value = val.target.value
      this.checkedValue = value
      if(value == 1) {
        this.followTypeName = '客户名称'
        this.getCustomerList()
        this.cus = {}
      }

      if(value == 2) {
        this.followTypeName = '渠道'
        this.getChannelList()
        this.cus = {}
      }
    },

    onCusChange(val) {
      this.$refs.test.onFieldChange()
      this.rowData.cusId = val.key
      this.rowData.cusName = val.label
    },

    // 获取员工
    getManager(id) {
      this.axios.get(`/api/base/achievements/dealerAchievements/manager/choose?storeId=${id}`).then((res) => {
        this.managerList = res.body
      })
    },

    onFollowChange(val) {
      this.$refs.text.onFieldChange()
      this.rowData.followUpPerson = val.label
      this.rowData.followUpId = val.key
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.managerId = this.$store.getters.userInfo.id
        // this.rowData.storeId = this.$store.getters.userInfo.storeId
        // if (this.rowData.storeId == null) {
        //   return this.$message.warning('当前帐号没有门店信息，不可以提交预订单')
        // }
        const res =
          this.handle === 'add'
            ? await addDealerHistoryFollowUp(this.rowData)
            : await editDealerHistoryFollowUp(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },

    // 关闭弹框
    onCancel() {
      this.visible = false
    },

    // 根据门店id获取该门店下的员工
    onStoreChange(val) {
      this.$refs.item.onFieldChange()
      this.getManager(val)
    },

    onDete(value) {
      this.$refs.tesxt.onFieldChange()
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.btns-footer {
  text-align: right;
  margin-top: 20px;
}
</style>
