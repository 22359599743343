<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-12 19:21:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-28 17:37:32
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <a-form-model ref="searchData" :label-col="{ xs: 7 }" :wrapper-col="{ xs: 17 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="客户名称">
              <a-input v-model="searchData.cusName" placeholder="请输入客户名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="手机号">
              <a-input v-model="searchData.phone" placeholder="请输入客户手机号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="跟进方式">
              <DictSelect
                field="followUpModes"
                :value.sync="searchData.followUpModes"
                style="width: 100%"
                placeholder="请选择跟进方式"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="跟进人">
              <a-input v-model="searchData.followUpPerson" placeholder="请输入跟进人" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
              <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <div class="table-operator">
        <a-button type="primary" v-if="addEnable" icon="plus" @click="toHandler('add', {})">新建</a-button>
      </div>

      <a-table
        :data-source="tableData"
        :loading="tableLoading"
        :rowKey="(record) => record.id"
        :pagination="page"
        bordered
        @change="changeTable"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <a-table-column title="序号" align="center">
          <template slot-scope="text, row, index">{{ index + 1 }}</template>
        </a-table-column>
        <a-table-column title="客户姓名" data-index="cusName" align="center"></a-table-column>
        <a-table-column title="手机号" data-index="phone" align="center"></a-table-column>
        <a-table-column title="跟进方式" data-index="followUpModes" align="center">
          <template slot-scope="text">
            <a-tag color="#909399" v-if="text == 1">登门拜访</a-tag>
            <a-tag color="#67c23a" v-if="text == 2">微信</a-tag>
            <a-tag color="#108ee9" v-if="text == 3">电话</a-tag>
          </template>
        </a-table-column>
        <a-table-column title="跟进人" data-index="followUpPerson" align="center"></a-table-column>
        <a-table-column title="跟进时间" data-index="FollowUpTime" align="center"></a-table-column>
        <a-table-column title="操作" align="center" :width="100">
          <template slot-scope="text, row">
            <a v-if="checkEnabel" @click="toHandler('check', row)">查看</a>
          </template>
        </a-table-column>
      </a-table>
    </div>
    <div class="footer-btns" v-if="item != undefined">
      <a-button @click="visible = false">取消</a-button>
      <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
    </div>
    <HistoryEidtModal ref="HistoryEidtModal" :item="item" @reload="getList(1)"></HistoryEidtModal>
  </a-card>
</template>

<script>
import HistoryEidtModal from './HistoryEidtModal.vue'
import { checkPermission } from '@/utils/permissions'


export default {
  components: {
    HistoryEidtModal,
  },

  data() {
    return {
      visible: false,
      tableLoading: false,
      btnsLoading: false,
      tableData: [],
      handle: '',
      searchData: {},
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      addEnable: checkPermission('dealer:History:add'),
      editEnabel: checkPermission('dealer:History:edit'),
      delEnabel: checkPermission('dealer:History:del'),
      checkEnabel: checkPermission('dealer:History:check'),
    }
  },

  props: {
    item: {
      type: [Object, Array],
      default: () => {},
    },
    cusId: {
      type: Number,
      default: 0
    }
  },

  created() {
    this.getList(0)
  },

  methods: {
    // 打开弹框触发
    isShow() {
      this.visible = true
      this.getList(0)
    },
    // 获取数据源
    getList(num) {
      this.tableLoading = true
      if (num === 1) {
        this.searchData = {}
      }
      this.axios
        .get(
          '/api/dealer/customer/dealerHistoryFollowUp/list?cusName=' +
            (this.searchData.cusName || '') +
            '&phone=' +
            (this.searchData.phone || '') +
            '&followUpModes=' +
            (this.searchData.followUpModes || '') +
            '&followUpPerson=' +
            (this.searchData.followUpPerson || '') +
            '&cusId=' +
            (this.cusId == 0 ? '' : this.cusId) +
            '&pageNumber=' +
            this.page.pageNumber
        )
        .then((res) => {
          if (res.code === 200) {
            this.tableData = res.body.records
            this.page.total = res.body.total
            this.tableLoading = false
          }
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    // 关闭弹框
    onCancel() {
      this.$emit('onload')
      this.visible = false
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    // 提交
    onSubmit() {
      this.$emit('onload')
      this.$emit('get-product-list', this.selectedRows)
      this.visible = false
      this.selectedRowKeys = []
    },

    toHandler(name, row) {
      const _this = this

      if (name === 'add') {
        _this.$refs.HistoryEidtModal.setRowData({}, 'add')
        return
      }

      switch (name) {
        case 'edit':
          _this.$refs.HistoryEidtModal.setRowData(row, 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerCustomerTask(row.id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getList(0)
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.HistoryEidtModal.setRowData(row, 'check')
          break
      }
    },
  },
}
</script>

<style scoped>
.steps-action {
  margin-top: 20px;
  text-align: right;
}
.steps-content {
  margin-top: 60px;
}
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>